import React, { useEffect, useState } from "react";
import CheckoutForm from "../components/molecules/CheckoutForm/CheckoutForm";
import { AllCartItems } from "../components/organisms/AllCartItems/AllCartItems";
import { PageTitle, SEO } from "../modules/seo";

const Checkout = () => {
  const [checkoutId, setCheckoutId] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    setCheckoutId(localStorage.getItem("checkoutId"));
    const t = localStorage.getItem("token");
    t && setToken(t);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 3,
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "30px",
          background: "#fff",
        }}
      >
        <SEO title={PageTitle.CheckOut} />
        {checkoutId && (
          <>
            <AllCartItems checkoutId={checkoutId} cart={true} />
            <CheckoutForm checkoutId={checkoutId} token={token} />
          </>
        )}
      </div>
    </div>
  );
};

export default Checkout;
